import React from 'react';
import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import MainLayout from 'src/layout/MainLayout';
import { SectionHead, Grid, Button, Paragraphs } from 'src/components';
import { Card, CardHeader } from 'src/components/Cards';

import content from 'src/content/unsere-loesungen/absicherung-fuer-leitende-angestellte';
import subpageNavigations from 'src/data/subpage-navigations';
import ProtectIcon from 'src/assets/icons/protect.svg';

const AbsicherungFuerLeitendeAngestelltePage = ({ data }) => (
  <MainLayout
    title={content.title}
    headvisual={{
      image: data.headvisual,
      headline: content.headvisualHeadline,
      position: '75'
    }}
    subpageNavItems={subpageNavigations.absicherungFuerLeitendeAngestellte}
  >
    <section>
      <SectionHead headline={content.sectionHead1.headline}>
        <Paragraphs items={content.sectionHead1.paragraphs} />
      </SectionHead>
    </section>
    <BackgroundImage
      fluid={data.sectionBackground.childImageSharp.fluid}
      className="full-width-image mx-auto max-w-1900px bg-center-33"
    />
    <section className="bg-light-blue">
      <SectionHead headline={content.sectionHead2.headline} centered shifted>
        <p>{content.sectionHead2.paragraph}</p>
      </SectionHead>
      <Grid>
        {content.cards.map((card, index) => (
          <Card key={index} noShadow>
            <CardHeader headline={card.headline} centered>
              <ProtectIcon />
              <h4 className="font-normal text-xl">{card.topline}</h4>
            </CardHeader>
            <p>{card.paragraph}</p>
            <Button href={card.buttonLink} variant="gray-blue" fullWidth>
              {card.buttonText}
            </Button>
          </Card>
        ))}
      </Grid>
    </section>
  </MainLayout>
);

export default AbsicherungFuerLeitendeAngestelltePage;

export const query = graphql`
  {
    headvisual: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "man-with-laptop-and-papers-wide.jpg" }
    ) {
      ...image
    }
    sectionBackground: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "man-in-office-with-cup-reading-paper.jpg" }
    ) {
      ...image
    }
  }
`;
