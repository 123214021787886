const content = {
  title: 'Absicherung für leitende Angestellte/GGF',
  headvisualHeadline: 'Absicherung für leitende Angestellte/GGF',
  sectionHead1: {
    headline:
      'Spezieller Schutz für Vorstandsmitglieder, Geschäftsführer und leitende Angestellte.',
    paragraphs: [
      'Gerade bei Gesellschaftern und sonstigen Führungskräften, die nicht renten­versicherungspflichtig sind, ist die Versorgungslücke im Alter besonders groß.',
      'Das liegt daran, dass Sie in der Regel mit Beginn der unternehmerischen Tätigkeit von der Sozialversicherung befreit sind und somit ab diesen Zeitpunkt aus der gesetzlichen Versorgung herausfallen. Folge ist ein häufig sehr geringer Anspruch der in der gesetzlichen Rentenversicherung erworben wurde. Wird es nun versäumt eine passende private und betriebliche Absicherung aufzubauen, sieht es im Alter schlecht aus. Deswegen heißt es hier rechtzeitig vorsorgen.'
    ]
  },
  sectionHead2: {
    headline: 'Die 3 Stufen der Geschäftsführerversorgung',
    paragraph:
      'Bei der sogenannten Chefversorgung, die im besten Fall 3 Stufenenthält, ist besonders die Kombination mehrerer Durchführungswege interessant. Dadurch können die steuerlichen Vorteile umfangreich genutzt werden.'
  },
  cards: [
    {
      topline: 'Stufe 1',
      headline: 'Direktversicherung',
      paragraph:
        'Die Direktversicherung ist der ideale Einstieg in die Chefversorgung und bietet vielfältige tarifliche Möglichkeiten.',
      buttonLink:
        '/unsere-loesungen/absicherung-fuer-leitende-angestellte/entgeltumwandlung-laufende-beitraege#direktversicherung',
      buttonText: 'Mehr erfahren'
    },
    {
      topline: 'Stufe 2',
      headline: 'Unterstützungskasse',
      paragraph:
        'Als 2. Stufe empfiehlt sich die Unterstützungskasse, da durch hohe Beiträge ein für die Stellung eines Gesellschafter-Geschäftsführers entsprechendes Rentenniveau erreicht werden kann.',
      buttonLink:
        '/unsere-loesungen/absicherung-fuer-leitende-angestellte/entgeltumwandlung-laufende-beitraege#unterstuetzungskasse',
      buttonText: 'Mehr erfahren'
    },
    {
      topline: 'Stufe 3',
      headline: 'Bonus-Vorsorge-Flex',
      paragraph:
        'Mit Hilfe der Bonus-Vorsorge-Flex als 3. Stufe der Chefvorsorge können Einmalzahlungen wie z. B. Tantiemenzahlungen steueroptimiert für die Altersvorsorge genutzt werden. Die Gestaltung als Beitragsorientierte Leistungszusage werden dabei Haftungssrisiken minimiert.',
      buttonLink:
        '/unsere-loesungen/absicherung-fuer-leitende-angestellte/entgeltumwandlung-laufende-beitraege#einmalbeitraege',
      buttonText: 'Mehr erfahren'
    }
  ]
};

export default content;
